import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private generalApiUrl = 'https://sellbackend.creditclan.com/merchantclan/public/index.php/api/';

  constructor(
    private http: HttpClient,
    private _auth: AuthService
  ) {
  }

  generateHttpOptions(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${ this._auth.token }`
    });
  }

  updateProfilePicture(picture: FormData): Observable<{ message: string; status: boolean }> {
    return this.http.post<any>(`${ this.generalApiUrl }profilepicture`, picture, {
      headers: this.generateHttpOptions()
    });
  }

  getAllCustomerRequests(page?: number): Observable<{
    data: any[]; end: boolean; next: null | number; prev: null | number; total_results_count: number; status: boolean;
  }> {
    const page_number = page ? page : '1';
    return this.http.get<any>(`${ this.generalApiUrl }customers/${ page_number }`, {
      headers: this.generateHttpOptions()
    });
  }

  modifyTotalPriceOfRequest(form, id: string) {
    return this.http.post(`${ this.generalApiUrl }request/${ id }/edit`, form, {
      headers: this.generateHttpOptions()
    });
  }

  deleteOrder(id: string) {
    return this.http.get(`https://sellbackend.creditclan.com/parent/index.php/globalrequest/cancelapplication/${ id }`);
  }

  searchOrdersOrRepaymentOrSales(obj: {
    search: string; page_number: any; sales?: boolean; repayments?: boolean; orders?: boolean;
  }) {
    return this.http.post(`${ this.generalApiUrl }search/orders`, obj, {
      headers: this.generateHttpOptions()
    });
  }

  sendReminderToCustomer(request_id) {
    return this.http.post(`${ this.generalApiUrl }reminder/${ request_id }`, null, { headers: this.generateHttpOptions() });
  }

  getStudents(page = 1) {
    const limit = 20;
    const offset = (page * limit) - limit;
    return this.http.get(`https://sellbackend.creditclan.com/parent/index.php/globalrequest/getmsbmstudent/${ offset }`);
  }

  searchStudents(search: string, page = 1) {
    const limit = 20;
    const offset = (page * limit) - limit;
    return this.http.get(`https://sellbackend.creditclan.com/parent/index.php/globalrequest/getmsbmstudent/${ offset }`, {
      params: { search }
    });
  }

  submitStudentApplication(payload) {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/createmsbmstudent', payload).toPromise<any>();
  }

  searchCourse(search) {
    return this.http.post(`https://sellbackend.creditclan.com/parent/index.php/globalrequest/searchmsbmcourses`, { search })
      .toPromise<any>();
  }

  getGlobalSettings() {
    return this.http.post('https://mobile.creditclan.com/api/v3/bnpl/global/settings', {}, {
      headers: new HttpHeaders({ 'x-api-key': 'WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228' })
    }).toPromise<any>();
  }

  updateApplicationEligibilityLink(id, payload) {
    return this.http.post(`https://sellbackend.creditclan.com/parent/index.php/globalrequest/updateapplication`, {
      id, payload, consent: false
    }).toPromise<any>();
  }

  getCcUserDetails(email, phone) {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/check/details', { email, phone }, {
      headers: new HttpHeaders({ 'x-api-key': 'WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228' })
    }).toPromise<any>();
  }

  getCcRequestDetails(token, request_id) {
    return this.http.post('https://mobile.creditclan.com/api/v3/loan/details', { token, request_id }, {
      headers: new HttpHeaders({ 'x-api-key': 'WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228' })
    }).toPromise<any>();
  }

  getCourses(page = 1) {
    const limit = 20;
    const offset = (page * limit) - limit;
    return this.http.get(`https://sellbackend.creditclan.com/parent/index.php/globalrequest/getallmsbmcourses/${ offset }`)
      .toPromise<any>();
  }

  updateCourse(course) {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/updatemsbmcourses/', {
      ...course
    }).toPromise<any>();
  }

  addCourse(course: any) {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/createnewcourse', {
      ...course
    }).toPromise<any>();
  }
}

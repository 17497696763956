import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeago'
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: string): any {
    if (!value) return value;
    return moment(new Date(value)).fromNow();
  }
}

<h5 class="title">New course</h5>

<form [formGroup]="courseForm" (ngSubmit)="handleSubmit()">
  <div class="form-group mb-4">
    <label for="course_title">Course title</label>
    <input type="text" formControlName="course_title" id="course_title" class="form-control"/>
  </div>
  <div class="form-group mb-4">
    <label for="description">Course description</label>
    <textarea
      formControlName="description" id="description" class="form-control" rows="4"
    ></textarea>
  </div>
  <div class="form-group mb-4">
    <label for="amount">Course amount</label>
    <input type="number" formControlName="amount" id="amount" class="form-control"/>
  </div>
  <div class="form-group mb-4">
    <label for="duration">Course duration</label>
    <select formControlName="tenor" id="duration" class="form-select">
      <option *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12]" [value]="i">{{ i }} month(s)</option>
    </select>
  </div>
  <div class="mt-5">
    <button type="submit" class="btn btn-primary">
      <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>
      Add course
    </button>
  </div>
</form>

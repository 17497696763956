<!-- Desktop -->
<div class="d-none d-md-block" style="padding: 2rem">
  <!--  Student info -->
  <div class="card overflow-hidden mb-4 p-0">
    <div style="padding: 2rem">
      <div class="d-flex">
        <div class="flex-grow-1">
          <h5 class="title m-0">{{ application?.full_name }}</h5>
          <div class="font-size-md mt-2 text-muted d-flex align-items-center">
            <i class="fa fa-envelope me-2"></i>
            {{ application?.email }}
          </div>
          <div class="font-size-md mt-2 text-muted d-flex align-items-center">
            <i class="fa fa-phone me-2"></i>
            {{ application?.phone }}
          </div>
        </div>
        <div class="ms-auto text-end">
          <ng-template #paymentSummary>
            <!-- Upfront info -->
            <h5 class="title m-0" style="font-size: 1.2rem">
              {{ application.loan_amount | currency:'NGN':'₦':'0.0' }}
            </h5>
            <small class="font-size-sm mt-3">{{ application?.created_at | date:'mediumDate' }}</small>
          </ng-template>
          <ng-container [ngTemplateOutlet]="paymentSummary"></ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- Actions -->
  <div
    *ngIf="!application.creditclan_request_id"
    class="card d-flex flex-row justify-content-around text-center mb-4"
  >
    <div (click)="copyEligibilityLink()" class="d-flex flex-column align-items-center cursor-pointer">
      <i class="fa fa-clone text-dark-blue font-size-lg"></i>
      <div class="font-size-md mt-2">Copy eligibility link</div>
    </div>
    <div (click)="shareEligibilityLink()" class="d-flex flex-column align-items-center cursor-pointer">
      <i class="fa fa-share-alt text-dark-blue font-size-lg"></i>
      <div class="font-size-md mt-2">Share eligibility link</div>
    </div>
  </div>

  <!-- Loan status -->
  <div *ngIf="application.creditclan_request_id && !request" class="mb-4 fst-italic text-muted font-size-md">
    Fetching loan eligibility status..
  </div>
  <div class="mb-4">
    <div *ngIf="!application.creditclan_request_id" class="alert alert-warning">Eligibility pending</div>
    <ng-template [ngIf]="application.creditclan_request_id">
      <div *ngIf="+application.loan_status >= 0 && +application.loan_status < 2" class="alert alert-info">
        Under final review
      </div>
      <div *ngIf="+application.loan_status >= 2" class="alert alert-success">Loan granted</div>
      <div *ngIf="+application.loan_status === -1" class="alert alert-danger">Loan not granted</div>
    </ng-template>
  </div>

  <ng-template [ngIf]="request?.loandetails?.SCHEDULE_HAS_BEEN_SAVED === '1'">
    <div class="card d-flex flex-row justify-content-around text-center mb-4">
      <div (click)="copyOfferLink()" class="d-flex flex-column align-items-center cursor-pointer">
        <i class="fa fa-clone text-dark-blue font-size-lg"></i>
        <div class="font-size-md mt-2">Copy offer link</div>
      </div>
      <div (click)="shareOfferLink()" class="d-flex flex-column align-items-center cursor-pointer">
        <i class="fa fa-share-alt text-dark-blue font-size-lg"></i>
        <div class="font-size-md mt-2">Share offer link</div>
      </div>
    </div>
    <div class="card p-4 mb-4">
      <h5 class="title">Offer</h5>
      <ng-template [ngIf]="request?.loandetails?.REQUEST_PERIOD_ID === '1'">
        <div class="d-flex justify-content-between align-items-center py-2 pt-0 border-bottom">
          <span>Total repayment</span>
          <span class="fw-bold">
            {{ request.loandetails.TOTAL_REPAYMENT | currency:'NGN':'₦':'0.0' }}
          </span>
        </div>
        <div class="d-flex justify-content-between align-items-center py-2 pb-0">
          <span>Next repayment date</span>
          <span class="fw-bold">
            {{ request.loandetails.NEXT_PAYMENT_DATE | date:'mediumDate' }}
          </span>
        </div>
      </ng-template>
      <ng-template [ngIf]="request?.loandetails?.REQUEST_PERIOD_ID === '2'">
        <div class="d-flex justify-content-between align-items-center py-2 pt-0 border-bottom">
          <span>Monthly repayment</span>
          <span class="fw-bold">
            {{ request.loandetails.NEXT_PAYMENT_AMOUNT | currency:'NGN':'₦':'0.0' }}
          </span>
        </div>
        <div class="d-flex justify-content-between align-items-center py-2 pb-0">
          <span>Next repayment date</span>
          <span class="fw-bold">
            {{ request.loandetails.NEXT_PAYMENT_DATE | date:'mediumDate' }}
          </span>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <!--  Course info -->
  <div class="card rounded-2 mt-4" style="padding: 2rem">
    <h5 class="title mb-2">Course title</h5>
    <div class="mb-4 font-size-md">
      {{ application.course_title }}
    </div>
    <h5 class="title mb-2">Course description</h5>
    <div class="font-size-md">{{ application.description }}</div>
  </div>
</div>

<!-- Mobile -->
<div class="d-md-none flex-grow-1 d-flex flex-column">
  <div class="px-4 pt-4 border-bottom">
    <h5 class="title m-0 d-flex align-items-center" style="font-size: 1.02rem">
      {{ application?.amount | currency:'NGN':'₦':'0.0' }}
    </h5>
    <div class="mt-3">
      <i class="fa fa-user me-2 text-muted"></i> {{ application?.full_name }}
    </div>
    <div class="mt-2">
      <i class="fa fa-phone me-2 text-muted"></i> {{ application?.phone_no || application?.email }}
    </div>
    <div class="mt-2">
      <small class="font-size-sm">{{ application?.created_at | date:'mediumDate' }}</small>
    </div>
    <div *ngIf="application.creditclan_request_id && !request" class="mt-4 fst-italic text-muted font-size-md">
      Fetching loan eligibility status..
    </div>
    <div class="mt-4">
      <div *ngIf="!application.creditclan_request_id" class="alert alert-warning fs-6 py-2 px-3">
        Eligibility pending
      </div>
      <ng-template [ngIf]="application.creditclan_request_id">
        <div
          *ngIf="+application.loan_status >= 0 && +application.loan_status < 2"
          class="alert alert-info fs-6 py-2 px-3"
        >
          Under final review
        </div>
        <div *ngIf="+application.loan_status >= 2" class="alert alert-success fs-6 py-2 px-3">Loan granted</div>
        <div *ngIf="+application.loan_status === -1" class="alert alert-danger fs-6 py-2 px-3">Loan not granted</div>
      </ng-template>
    </div>
  </div>

  <div class="p-4 bg-light flex-grow-1">
    <div *ngIf="request?.loandetails?.SCHEDULE_HAS_BEEN_SAVED === '1'" class="card p-4 mb-4">
      <h5 class="title">Offer</h5>
      <ng-template [ngIf]="request?.loandetails?.REQUEST_PERIOD_ID === '1'">
        <div class="d-flex justify-content-between align-items-center py-2 pt-0 border-bottom">
          <span>Total repayment</span>
          <span class="fw-bold">
            {{ request.loandetails.TOTAL_REPAYMENT | currency:'NGN':'₦':'0.0' }}
          </span>
        </div>
        <div class="d-flex justify-content-between align-items-center py-2 pb-0">
          <span>Next repayment date</span>
          <span class="fw-bold">
            {{ request.loandetails.NEXT_PAYMENT_DATE | date:'mediumDate' }}
          </span>
        </div>
      </ng-template>
      <ng-template [ngIf]="request?.loandetails?.REQUEST_PERIOD_ID === '2'">
        <div class="d-flex justify-content-between align-items-center py-2 pt-0 border-bottom">
          <span>Monthly repayment</span>
          <span class="fw-bold">
            {{ request.loandetails.NEXT_PAYMENT_AMOUNT | currency:'NGN':'₦':'0.0' }}
          </span>
        </div>
        <div class="d-flex justify-content-between align-items-center py-2 pb-0">
          <span>Next repayment date</span>
          <span class="fw-bold">
            {{ request.loandetails.NEXT_PAYMENT_DATE | date:'mediumDate' }}
          </span>
        </div>
      </ng-template>
    </div>

    <div>
      <div class="fw-bold mb-3">Course title</div>
      <div class="mb-4 font-size-md">
        {{ application.course_title }}
      </div>
      <div class="fw-bold mb-3">Course description</div>
      <div class="font-size-md">{{ application.description }}</div>
    </div>
  </div>

  <br><br><br>
</div>

<button
  *ngIf="!application.creditclan_request_id || request?.loandetails?.SCHEDULE_HAS_BEEN_SAVED === '1'"
  (click)="modal = 'options'" class="floating-action-button d-md-none"
>
  <i class="fa fa-stream"></i>
</button>

<!-- Mobile option -->
<app-dialog
  *ngIf="modal === 'options'" (close)="modal = ''" [close-button]="false" #optionsDialog size="sm"
  [nested]="true"
>
  <div *ngIf="!application.creditclan_request_id" class="row text-center">
    <div class="col-6">
      <div
        (click)="copyEligibilityLink()"
        class="d-flex flex-column align-items-center cursor-pointer py-4"
      >
        <i class="fa fa-clone text-info" style="font-size: 1.3rem;"></i>
        <small class="mt-2">Copy eligibility link</small>
      </div>
    </div>
    <div class="col-6">
      <div
        (click)="shareEligibilityLink()"
        class="d-flex flex-column align-items-center cursor-pointer py-4"
      >
        <i class="fa fa-share-alt text-warning" style="font-size: 1.3rem;"></i>
        <small class="mt-2">Share eligibility link</small>
      </div>
    </div>
  </div>

  <div *ngIf="request?.loandetails?.SCHEDULE_HAS_BEEN_SAVED === '1'" class="row text-center">
    <div class="col-6">
      <div
        (click)="copyOfferLink()"
        class="d-flex flex-column align-items-center cursor-pointer py-4"
      >
        <i class="fa fa-clone text-info" style="font-size: 1.3rem;"></i>
        <small class="mt-2">Copy offer link</small>
      </div>
    </div>
    <div class="col-6">
      <div
        (click)="shareOfferLink()"
        class="d-flex flex-column align-items-center cursor-pointer py-4"
      >
        <i class="fa fa-share-alt text-warning" style="font-size: 1.3rem;"></i>
        <small class="mt-2">Share offer link</small>
      </div>
    </div>
  </div>
</app-dialog>

<!-- Share -->
<app-dialog *ngIf="modal === 'share'" [nested]="true" (close)="modal = ''" title="Share invoice" size="sm">
  <div class="row">
    <div class="col-md-6">
      <div (click)="shareToWhatsapp()" class="card text-center p-5 cursor-pointer mb-4">
        <i class="fab fa-whatsapp text-success fa-4x"></i>
        <div class="mt-4">Share to whatsapp</div>
      </div>
    </div>
    <div class="col-md-6">
      <div (click)="copyEligibilityLink()" class="card text-center p-5 cursor-pointer">
        <i class="fa fa-clipboard text-primary fa-4x"></i>
        <div class="mt-4">Copy link</div>
      </div>
    </div>
  </div>
</app-dialog>

<!-- Delete order confirmation modal -->
<app-dialog
  *ngIf="modal === 'delete'" [nested]="true" (close)="modal = ''" [close-button]="false" [confirmation]="true"
  #dialogDelete [closable]="false"
  size="sm"
>
  <div class="title m-0 text-center">
    Are you sure you want to cancel this order?
  </div>
  <div class="d-flex justify-content-center mt-4">
    <button (click)="dialogDelete.dismiss()" class="btn btn-outline-primary checkoutBtn" [disabled]="deleting">No
    </button>
    <button (click)="cancelApplication()" class="btn btn-danger checkoutBtn ms-2" [disabled]="deleting">Yes, cancel
    </button>
  </div>
</app-dialog>


import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-add-course',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.scss']
})
export class AddCourseComponent implements OnInit {

  @Output() done = new EventEmitter();
  courseForm: FormGroup;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private _api: ApiService,
    private _general: GeneralService,
  ) {
  }

  ngOnInit(): void {
    this.courseForm = this.fb.group({
      course_title: ['', Validators.required],
      description: ['', Validators.required],
      amount: ['', [Validators.required, Validators.pattern(/^\d+$/gi)]],
      tenor: ['', Validators.required],
    });
  }

  async handleSubmit() {
    const values = this.courseForm.value;
    const payload = {
      ...values,
      duration: `${ values.tenor } ${ (+values.tenor === 1 ? 'month' : 'months') }`,
      locations: null,
      tenor_type: '2'
    };
    try {
      this.loading = true;
      this.courseForm.disable();
      await this._api.addCourse(payload);
      this._general.notify('success', 'Course added');
      this.courseForm.enable();
      this.done.emit();
    } catch (e) {
      console.log({ e });
      this.courseForm.enable();
      this._general.notify('error', e?.error?.message || 'Unable to login');
    }
  }
}

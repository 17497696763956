import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './services/auth.service';
import { of } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private _auth: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const from = `${ route.url[0].path }`;
    if (this._auth.authenticated) return true;
    const token = localStorage.getItem('token');
    if (!token) return this.router.createUrlTree(['/login'], { queryParams: { from } });
    return this._auth.getSchoolFromToken(token).pipe(
      map((res: any) => {
        this._auth.authenticate({ ...res.data });
        return true;
      }),
      catchError(() => {
        this._auth.logout();
        return of(this.router.createUrlTree(['/login'], { queryParams: { from } }));
      })
    );
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-edit-course',
  templateUrl: './edit-course.component.html',
  styleUrls: ['./edit-course.component.scss']
})
export class EditCourseComponent implements OnInit {

  @Output() done = new EventEmitter();
  @Input() course = null;
  courseForm: FormGroup;
  loading = false;


  constructor(
    private fb: FormBuilder,
    private _api: ApiService,
    private _general: GeneralService,
  ) {
  }

  ngOnInit(): void {
    console.log(this.course);
    const { course_title, description, amount } = this.course;
    this.courseForm = this.fb.group({
      course_title: [course_title, Validators.required],
      description: [description, Validators.required],
      amount: [amount, [Validators.required, Validators.pattern(/^\d+$/gi)]],
    });
  }

  async handleSubmit() {
    const values = this.courseForm.value;
    const payload = { ...this.course, ...values };
    try {
      this.loading = true;
      await this._api.updateCourse(payload);
      this._general.notify('success', 'Course updated');
      this.done.emit(payload);
    } catch (e) {
      console.log({ e });
      this._general.notify('error', e?.error?.message || 'Unable to login');
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  courses = [];
  pagination = {
    page: 1,
    end: false
  };
  loading = false;
  fetching = false;
  modal = '';
  current = null;

  constructor(
    private _api: ApiService
  ) {
  }

  ngOnInit(): void {
    this.getCourses(this.pagination.page);
  }

  async getCourses(page = 1) {
    if (page === 1) this.loading = true;
    else this.fetching = true;
    try {
      const res = await this._api.getCourses(page);
      this.courses = page > 1 ? [...this.courses, ...res.data] : res.data;
      this.pagination.page = page;
      if (!res.data.length || res.data.length < 20) this.pagination.end = true;
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
    this.fetching = false;
  }

  loadMore() {
    if (this.pagination.end) return;
    this.getCourses(this.pagination.page + 1);
  }

  handleEdit(course: any) {
    this.current = course;
    this.modal = 'edit';
  }

  handleEditDone(updated) {
    this.courses = this.courses.map(course => {
      if (course.id === updated.id) return updated;
      return course;
    });
    this.current = null;
    this.modal = '';
  }

  handleAddDone() {
    this.modal = '';
    this.getCourses();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { GeneralService } from 'src/app/services/general.service';
import { IRequest } from 'src/app/models/requests';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.css']
})
export class StudentDetailsComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Output() update = new EventEmitter();
  @Input() application: IRequest | any;
  loading = true;
  deleting = false;
  view = 'details';
  mobile = window.innerWidth < 768;
  modal = '';
  password = '';
  schedule = null;
  request = null;

  constructor(
    private _api: ApiService,
    public _general: GeneralService,
    public _auth: AuthService,
  ) {
  }

  public get card(): any {
    return JSON.parse(this.application.card) || {};
  }

  ngOnInit(): void {
    const { creditclan_request_id } = this.application;
    if (creditclan_request_id) this.getCreditclanRequest(creditclan_request_id);
  }

  async getCreditclanRequest(id) {
    const { email, phone } = this.application;
    const { token } = await this._api.getCcUserDetails(email, phone);
    const res = await this._api.getCcRequestDetails(token, id);
    this.request = res.data;
  }

  cancelApplication() {
    this.deleting = true;
    this._api.deleteOrder(`${ this.application.id }`).subscribe(() => {
      this.close.emit(true);
    }, () => {
      this.modal = '';
      this.deleting = false;
      this._general.notify('error', 'An error occurred. Please try again.');
    });
  }

  shareToWhatsapp() {
    if (this.application.phone_no) {
      const link = `https://wa.me/+234${ this.application.phone_no.substring(1) }?text=https://zen-knuth-57e93d.netlify.app/request/${ this.application.id }`;
      window.open(link, '_blank');
    } else {
      this._general.notify('error', 'No phone number provided by customer');
    }
  }

  copyEligibilityLink() {
    const link = this.application.card_tokenization_link;
    this._general.copyLink(link);
  }

  shareEligibilityLink() {
    const url = this.application.card_tokenization_link;
    window.navigator?.share({
      title: 'Request',
      text: `Check status of your request`,
      url
    }).catch(error => console.log('Error sharing', error));
  }

  copyOfferLink() {
    const link = this.request?.loandetails?.offer_link;
    if (!link) return;
    this._general.copyLink(link);
  }

  shareOfferLink() {
    const url = this.request?.loandetails?.offer_link;
    if (!url) return;
    window.navigator?.share({
      title: 'Offer letter',
      text: `View your offer letter`,
      url
    }).catch(error => console.log('Error sharing', error));
  }
}

<h5 class="title">Edit course</h5>

<form [formGroup]="courseForm" (ngSubmit)="handleSubmit()">
  <div class="form-group mb-4">
    <label for="course_title">Course title</label>
    <input type="text" formControlName="course_title" id="course_title" class="form-control" [disabled]="loading"/>
  </div>
  <div class="form-group mb-4">
    <label for="description">Couse description</label>
    <textarea
      formControlName="description" id="description" class="form-control" [disabled]="loading" rows="4"
    ></textarea>
  </div>
  <div class="form-group mb-4">
    <label for="amount">Course amount</label>
    <input type="number" formControlName="amount" id="amount" class="form-control" [disabled]="loading"/>
  </div>
  <div class="mt-5">
    <button type="submit" class="btn btn-primary">
      <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>
      Update course
    </button>
  </div>
</form>

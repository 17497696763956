<app-header></app-header>

<main class="nav-margin">
  <div class="container pt-3 align-items-stretch d-none d-md-block">
    <div class="row align-items-stretch">
      <div class="col-lg-3">
      </div>
    </div>
  </div>

  <!-- MOBILE -->
  <!-- Floating action button -->
  <button (click)="openSupportWhatsappLink()" class="floating-action-button d-md-none">
    <i class="fab fa-whatsapp fa-2x"></i>
  </button>
</main>

<app-dialog *ngIf="modal === 'create-order'" (close)="modal = ''" [fullscreen]="true" title="Create order">
  <app-new-student></app-new-student>
</app-dialog>

<app-dialog *ngIf="modal === 'reg-create-product'" (close)="this.modal = ''" [close-button]="false">
  <div class="px-5 pt-5 text-center">
    <img src="/assets/images/svg/deliveries.svg" style="max-width: 300px;" alt="illustration" class="mx-auto">
    <h5 class="title mt-5">
      Welcome to your store
    </h5>
    <p style="max-width: 400px; margin: auto;">
      Create products, manage orders, repayments, sales and customers,
      click add products below to begin adding your products
    </p>
    <button class="btn btn-primary mt-5 mb-3">
      Close
    </button>
  </div>
</app-dialog>

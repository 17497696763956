import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
// external modules and custom modules
import { ToastrModule } from 'ngx-toastr';

import { IsLoggedInGuard } from './is-logged-in.guard';
import { AuthGuard } from './auth.guard';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { DefaultLayoutComponent } from './components/default-layout/default-layout.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { BottomNavComponent } from './components/shared/bottom-nav/bottom-nav.component';
import { NewStudentComponent } from './components/create-order/new-student.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { TimeAgoPipe } from './pipes/timeago.pipe';
import { ShortenNumberPipe } from './pipes/shorten-number.pipe';
import { SquareBoxComponent } from './components/square-box/square-box.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { ProxyImageComponent } from './components/proxy-image/proxy-image.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import { SwiperModule } from 'swiper/angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { StudentsComponent } from './components/students/students.component';
import { StudentDetailsComponent } from './components/student-details/student-details.component';
import { CoursesComponent } from './components/courses/courses.component';
import { EditCourseComponent } from './components/edit-course/edit-course.component';
import { AddCourseComponent } from './components/add-course/add-course.component';
import { RegisterComponent } from './components/auth/onboard/register.component';

SwiperCore.use([Navigation, Pagination]);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    SplashScreenComponent,
    DefaultLayoutComponent,
    HeaderComponent,
    FooterComponent,
    BottomNavComponent,
    NewStudentComponent,
    DialogComponent,
    TimeAgoPipe,
    ShortenNumberPipe,
    SquareBoxComponent,
    CapitalizePipe,
    ProxyImageComponent,
    StudentsComponent,
    StudentDetailsComponent,
    HeaderComponent,
    DashboardComponent,
    CoursesComponent,
    EditCourseComponent,
    AddCourseComponent,
    RegisterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      preventDuplicates: false
    }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    ImageCropperModule,
    CarouselModule,
    FormsModule,
    SwiperModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    RouterModule,
    AngularEditorModule,
  ],
  providers: [AuthGuard, IsLoggedInGuard],
  bootstrap: [AppComponent]
})
export class AppModule {
}

<div class="row wrap">
  <div class="col-0 col-sm-6 left-content py-5 d-none d-md-block"></div>
  <div class="col-12 col-md-6 left">
    <div class="content d-flex flex-column">
      <div class="my-auto">
        <div *ngIf="view === 'profile'">
          <form [formGroup]="profileForm">
            <h5 class="title mb-5">
              Profile Information
            </h5>
            <div class="form-group mb-4">
              <label for="full_name">Full name</label>
              <input formControlName="legal_name" class="form-control" type="text" id="full_name" min="0">
            </div>
            <div class="form-group mb-4">
              <label for="email">Email address</label>
              <input formControlName="email" class="form-control" type="email" id="email">
            </div>
            <div class="form-group mb-4">
              <label for="phone">Phone number</label>
              <input formControlName="phone" class="form-control" type="tel" id="phone">
            </div>
          </form>
          <form class="mt-5" [formGroup]="courseForm">
            <h5 class="title mb-5">
              Course Information
            </h5>
            <div *ngIf="!course" class="form-group mb-4">
              <label class="form-label">Course</label>
              <div class="d-flex align-items-center">
                <div class="input-group mb-3">
                  <input
                    [(ngModel)]="query" [ngModelOptions]="{standalone: true}"
                    type="text" class="form-control" id="search-course" placeholder="Recipient's username"
                  >
                  <button (click)="handleSearch()" class="btn btn-outline-secondary" type="button" id="button-addon2">
                    Search
                  </button>
                </div>
              </div>

              <div *ngIf="searching" class="mt-2">Searching..</div>
              <div *ngIf="!searching && searchedCourses.length" class="list-group rounded-2 overflow-hidden border">
                <div class="list-group-item px-3 py-2 font-size-sm">
                  Select a course
                </div>
                <div style="max-height: 350px; overflow-y: auto">
                  <div
                    *ngFor="let course of searchedCourses" (click)="selectCourse(course)"
                    class="list-group-item border-start-0 border-end-0 px-3 py-3 card-hover"
                  >
                    <div class="font-size-md">{{ course.course_title }}</div>
                    <div class="d-flex align-items-center font-size-sm text-muted mt-2">
                      <div class="d-flex">
                        {{ course.amount | currency:'NGN':'₦':'0.0' }}
                      </div>
                      <span class="mx-1">•</span>
                      <div class="d-flex">{{ course.duration }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="course" class="card p-3 d-flex flex-row align-items-center">
              <i class="fa fa-check text-success me-3"></i>
              <div class="flex-grow-1">
                <div class="font-size-md">{{ course.course_title }}</div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center font-size-sm text-muted mt-2">
                    <div class="d-flex">
                      {{ course.amount | currency:'NGN':'₦':'0.0' }}
                    </div>
                    <span class="mx-1">•</span>
                    <div class="d-flex">{{ course.duration }}</div>
                  </div>
                  <button (click)="changeCourse()" class="btn btn-link btn-sm text-danger">Change</button>
                </div>
              </div>
            </div>
          </form>
          <div class="mt-4">
            <button
              type="button" (click)="handleSubmit()" class="btn btn-primary mt-4"
              [disabled]="courseForm.invalid || profileForm.invalid"
            >
              Submit application
            </button>
          </div>
        </div>

        <div *ngIf="view === 'submitting'">
          <br><br><br><br><br>
          <div class="loader-bounce">
            <span class="circle"></span>
            <span class="circle"></span>
            <span class="circle"></span>
          </div>
          <div class="text-center mt-5">
            <h5 class="w-75 my-0 mx-auto">
              Please wait
            </h5>
            <p class="mt-3 text-muted mx-auto" style="max-width: 300px">We are sending your request</p>
          </div>
        </div>

        <div *ngIf="view === 'success'" class="d-flex flex-column align-items-center text-center pt-5 mt-5">
          <i class="fa fa-check-circle text-success fa-10x"></i>
          <h5 class="title mt-5">Application created successfully</h5>
          <button (click)="copyEligibilityLink()" class="btn btn-outline-secondary mt-2">Copy eligibility link</button>
        </div>
      </div>
    </div>
  </div>
</div>

<header class="header fixed-top" [ngClass]="{ hidden: scrolled && scrollDirection === 'down' }">
  <div class="sticky-wrapper">
    <div class="header-bottom sticky-header d-none d-lg-block">
      <div class="container d-flex justify-content-between" style="height: 70px">
        <div class="d-flex align-items-center flex-grow-1 flex-md-grow-0 overflow-hidden">
          <div
            *ngIf="!mobile || !currentRoute" (click)="_general.navigateTo('/students')"
            class="d-none d-md-flex align-items-center"
          >
            <div class="overflow-visible">
              <img src="assets/images/logo.png" alt="" style="height: 25px" class="rounded">
            </div>
          </div>

          <div *ngIf="!mobile || !currentRoute" class="d-flex d-md-none align-items-center oveflow-hidden w-100">
            <div class="overflow-visible">
              <img src="assets/images/alt-logo.png" alt="" style="width: 50px" class="rounded">
            </div>
            <h5 class="m-0 ms-3 business-name text-truncate overflow-hidden">
              Hi, {{ _auth.school.name }}
            </h5>
          </div>

          <h5 class="m-0" *ngIf="mobile && currentRoute">
            <button (click)="goBack()" class="btn btn-link px-0 me-3">
              <i class="fa fa-arrow-left"></i>
            </button>
            {{ currentRoute | titlecase }}
          </h5>
          <div class="d-flex align-items-center ms-auto d-md-none">
            <button *ngIf="showSearch" (click)="search.emit()" class="btn icon-button bg-transparent">
              <i class="fa fa-search text-muted"></i>
            </button>
            <button (click)="mobileNavVisible = true" class="btn icon-button ms-2 bg-transparent text-muted">
              <i class="fa fa-bars"></i>
            </button>
          </div>
        </div>

        <nav class="main-nav flex-grow-1 d-none d-md-flex ms-2">
          <ul id="target" class="menu mx-auto" style="touch-action: pan-y;">
            <li (click)="_general.navigateTo('/students')" [class.active]="currentRoute == 'students'">
              <a href="javascript:void(0)">Home</a>
            </li>
            <li (click)="_general.navigateTo('/courses')" [class.active]="currentRoute == 'courses'">
              <a href="javascript:void(0)" class="position-relative">Courses</a>
            </li>
          </ul>

          <ul class="menu">
            <li class="dropdown">
              <a
                class="d-inline-flex align-items-center dropdown-toggle position-relative overflow-hidden" type="button"
                id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"
              >
                <span class="overflow-hidden text-truncate" style="width: 200px;">
                Hi, {{ _auth.school.name }}
                </span>
                <i class="fa fa-chevron-down ms-2"></i>
              </a>
              <div
                class="dropdown-menu dropdown-menu-end pt-0" aria-labelledby="dropdownMenuButton2"
                style="width: 300px"
              >
                <div class="bg-off-white d-flex align-items-center border-bottom p-4 w-100 mb-2 header-logo">
                  <div *ngIf="_auth.school.picture" class="overflow-visible">
                    <img [src]="_auth.school.picture" alt="" style="height: 40px" class="rounded border">
                  </div>
                  <div *ngIf="!_auth.school.picture" class="avatar bg-light-grey overflow-visible">
                    {{ _auth.school.name.substr(0, 2).toUpperCase() }}
                  </div>
                  <h5 class="m-0 ms-3 business-name">{{ _auth.school.name }}</h5>
                </div>
                <span class="divider"></span>
                <a (click)="_auth.logout()" class="py-2 dropdown-item cursor-pointer">
                  <i class="text-muted fa fa-power-off me-2"></i> Logout
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { StoreService } from 'src/app/services/store.service';
import { IRequest } from 'src/app/models/requests';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  students: Array<IRequest> = [];
  searched = {
    searching: false,
    query: '',
    data: [],
    pagination: {
      page: 1,
      end: true
    }
  };
  loading = false;
  fetching = false;
  modal = '';
  currentStudent = null;
  mobile = window.innerWidth < 768;
  pagination = {
    page: 1,
    end: true
  };
  filterOptions = {
    creditOrOutright: 'all',
    confirmationStatus: 'none',
    deliveryStatus: 'none',
    payment: 'none'
  };
  total = 0;
  timeout = null;

  constructor(
    private _api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public _general: GeneralService,
    public _auth: AuthService,
    public _store: StoreService
  ) {
  }

  public get items(): any[] {
    if (this.searched.searching) return this.searched.data;
    else return this.students;
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        const student = this.students.find(r => r.id.toString() === params.id.toString());
        if (student) {
          this.currentStudent = student;
          this.modal = 'view';
        } else {
          this.router.navigate([`/students`]);
        }
      }
    });

    if (!this._store.students.items?.length) this.fetchStudents(1);

    this.subscriptions.push(
      this._store.$students.subscribe({
        next: ({ items, pagination, total }) => {
          if (!items) return;
          this.students = items;
          this.total = total;
          if (this.pagination) this.pagination = pagination;
        }
      })
    );
  }

  handleSearchInputChange(e) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const query = e.target.value;
      if (query) {
        this.search(query);
        this.searched.searching = true;
        this.searched.query = query;
      } else {
        this.searched = { searching: false, data: [], pagination: { page: 1, end: true }, query: '' };
      }
    }, 1000);
  }

  search(query, page_number = 1) {
    this[page_number === 1 ? 'loading' : 'fetching'] = true;
    this._api.searchStudents(query, page_number).subscribe((res: any) => {
      const { data, total } = res;
      if (page_number === 1) {
        this.searched.data = data;
      } else {
        this.searched.data = [...this.searched.data, ...data];
      }
      this.searched.pagination.page = page_number;
      this.searched.pagination.end = this.searched.data.length === total;
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
    }, (err) => {
      console.log(err);
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
      this._general.notify('error', 'Unable to fetch students, please check your network and reload');
    });
  }

  fetchStudents(page_number = 1) {
    this[page_number === 1 ? 'loading' : 'fetching'] = true;
    this._api.getStudents(page_number).pipe(take(1)).subscribe((res: any) => {
      const { data, total } = res;
      if (page_number === 1) {
        this._store.setState('students', {
          ...this._store.students,
          items: data,
          pagination: { ...this.pagination, end: data.length === total },
          total
        }, true);
      } else {
        this._store.setState('students', {
          ...this._store.students,
          items: [...this.students, ...data],
          pagination: { ...this.pagination, end: data.length === total },
          total
        }, true);
      }
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
    }, (err) => {
      console.log(err);
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
      this._general.notify('error', 'Unable to fetch students, please check your network and reload');
    });
  }

  async viewStudent(student, view = 'details') {
    await this.router.navigate([`/students`], { queryParams: { id: student.id, view } });
  }

  async closeViewStudent(refresh) {
    if (refresh) this.fetchStudents(1);
    this.modal = '';
    this.currentStudent = null;
    await this.router.navigate([`/students`]);
  }

  updateCurrentStudent(student) {
    this.currentStudent = student;
    const items = this._store.students.items.map(r => r.id === student.id ? student : r);
    this._store.setState('students', { ...this._store.students, items }, true);
  }

  loadMore() {
    if (this.searched.searching) {
      if (!this.searched.pagination.end) {
        this.search(this.searched.query, ++this.searched.pagination.page);
      }
    } else {
      if (!this.pagination.end) this.fetchStudents(++this.pagination.page);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

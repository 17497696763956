import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { ApiService } from 'src/app/services/api.service';
import { StoreService as GlobalStoreService } from 'src/app/services/store.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  modal = '';
  top = '';

  constructor(
    private router: Router,
    public _general: GeneralService,
    public _store: GlobalStoreService,
    public _auth: AuthService,
    private _api: ApiService
  ) {
  }

  openSupportWhatsappLink() {
    const link = `https://wa.me/+2349053535333?text=Hello`;
    window.open(link, '_blank');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(element => element.unsubscribe());
  }

  ngOnInit(): void {
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { of } from 'rxjs';

@Injectable()
export class IsLoggedInGuard implements CanActivate {

    constructor(private _auth: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = localStorage.getItem('token');
        if (!this._auth.authenticated && !token) return true;
        if (!token) return true;
        return of(this.router.createUrlTree(['/students']));
    }
}

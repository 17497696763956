<div class="bottom-nav d-flex d-md-none">
  <a [routerLink]="['/students']" [routerLinkActive]="['active']" class="bottom-nav-item">
    <div class="icon"><i class="fa fa-home"></i></div>
    <small class="text">Home</small>
  </a>
  <a [routerLink]="['/courses']" [routerLinkActive]="['active']" class="bottom-nav-item">
    <div class="icon"><i class="fas fa-book"></i></div>
    <small class="text">Courses</small>
  </a>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StoreService } from './store.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SchoolProfileDetails } from 'src/app/models/school';
import { AuthResponse } from 'src/app/models/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  api = 'https://sellbackend.creditclan.com/schoolcredit/public/index.php/api';
  private isAuthenticated = false;

  constructor(private http: HttpClient, private router: Router, private _store: StoreService) {
  }

  private _school: SchoolProfileDetails = null;

  public $school = new BehaviorSubject<SchoolProfileDetails>(this._school);

  public get school(): SchoolProfileDetails {
    return this._school;
  }

  public get token(): string {
    return localStorage.getItem('token') || null;
  }

  public get authenticated(): boolean {
    return this.isAuthenticated;
  }

  saveToken(token) {
    localStorage.setItem('token', token);
  }

  setSchool(school: SchoolProfileDetails) {
    this._school = school;
    this.$school.next(this._school);
  }

  updateSchool(data) {
    this._school = { ...this._school, ...data };
    this.$school.next(this._school);
  }

  authenticate(school) {
    this.setSchool(school);
    this.isAuthenticated = true;
  }

  getSchoolFromToken(token) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${ token }` });
    return this.http.get(`${ this.api }/school/data`, { headers });
  }

  async refreshSchoolData() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${ token }` });
    const data = await this.http.get(`${ this.api }/school/data`, { headers }).toPromise<any>();
    const school = data.data;
    this.updateSchool({ ...school });
  }

  login(
    data: Partial<{
      email: string;
      password: string;
      phone: string;
    }>
  ): Promise<AuthResponse> {
    return this.http.post<any>(`${ this.api }/school/login`, data).toPromise();
  }

  async logout() {
    this.isAuthenticated = false;
    this._school = null;
    this.$school.next(null);
    this._store.reset();
    localStorage.removeItem('token');
    await this.router.navigate(['/login']);
  }
}

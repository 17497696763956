import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-new-student',
  templateUrl: './new-student.component.html',
  styleUrls: ['./new-student.component.css']
})
export class NewStudentComponent implements OnInit, OnDestroy {
  @Output() refresh = new EventEmitter();

  profileForm: FormGroup;
  courseForm: FormGroup;
  searching = false;
  link = '';
  subscriptions: Subscription[] = [];
  schedule = [];
  history: string[] = [];
  searchedCourses = [];
  course = null;
  query = '';

  constructor(
    private fb: FormBuilder,
    private _api: ApiService,
    private _auth: AuthService,
    private _general: GeneralService
  ) {
  }

  public get view(): string {
    return this.history[this.history.length - 1];
  }

  pushView(view: string) {
    if (this.history.includes(view)) {
      this.history = this.history.reduce((acc, v) => {
        if (!acc.includes(view)) {
          acc.push(v);
        }
        return acc;
      }, []);
    } else {
      this.history.push(view);
    }
  }

  popView() {
    this.history.pop();
  }

  ngOnInit(): void {
    this.profileForm = this.fb.group({
      legal_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/(^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$)/)]],
      phone: ['', [Validators.required, Validators.pattern(/(^[0]\d{10}$)/)]],
      date_of_birth: [''],
      marital_status: [''],
      gender: [''],
    });
    this.courseForm = this.fb.group({
      course_id: ['', Validators.required]
    });
    this.pushView('profile');
  }

  async handleSearch() {
    if (!this.query) return;
    await this.searchCourse(this.query);
  }

  async searchCourse(query) {
    this.searching = true;
    const res = await this._api.searchCourse(query);
    this.searchedCourses = res.data;
    this.searching = false;
  }

  selectCourse(course) {
    this.searchedCourses = [];
    this.course = course;
    this.courseForm.get('course_id').setValue(course.id);
  }

  changeCourse() {
    this.searchedCourses = [];
    this.course = null;
    this.courseForm.get('course_id').setValue('');
  }

  async handleSubmit() {
    if (this.courseForm.invalid || this.profileForm.invalid) return;
    await this.submitApplication();
  }

  async submitApplication() {
    const profile = this.profileForm.value;
    const token = this._auth.token;
    const request = {
      amount: this.course.amount,
      tenor: this.course.course_duration,
      tenor_type: '2',
      product_id: '29955',
      course_name: this.course.course_title,
      course_id: this.course.id
    };
    const admin = {
      user_id: token
    };
    const payload = { profile, request, admin };
    this.pushView('submitting');
    try {
      const res = await this._api.submitStudentApplication(payload);
      if (!res.status) {
        this._general.notify('error', res.message || 'An error occurred, please try again');
        this.popView();
      }
      await this.saveEligibilityLink(res);
      this.refresh.emit();
      this.pushView('success');
    } catch (e) {
      console.log({ e });
      this.popView();
    }
  }

  async saveEligibilityLink(response) {
    const { school_request_id } = response;
    const payload = await this.generateEligibilityPayload(response);
    await this.updateEligibilityLink(school_request_id, payload);
  }

  async generateEligibilityPayload(response) {
    const global_settings = await this.getGlobalSetting();
    const { lender_id, lender_name, image: lender_image } = global_settings?.funding_partner ?? {};
    const lender = { id: lender_id, name: lender_name, image: lender_image };
    const {
      school_request_id, school_id, parent_id,
      data: {
        profile: { legal_name: full_name, ...profile },
        request: { amount }
      },
      course: { tenor, tenor_type }
    } = response;
    const { course_title, description, duration, amount: course_amount, image: course_image } = this.course;
    return {
      banner: 'https://merchants.creditclan.com/assets/images/man-smiling.jpeg',
      request: { amount, tenor, tenor_type, product_id: '29955' },
      profile: { ...profile, full_name },
      lender,
      config: {
        no_frequently_called_numbers: 0,
        analyze_bank_statement: true,
        tokenize_card: false,
        show_offers: false,
        show_signature: false,
        show_bank_account: true,
        show_questions: false,
        match_account_with_bvn: true,
        show_work_information: true,
        platform: 'school-parent',
        show_nin: false,
        show_consent: true,
        show_address: true,
        scan_whatsapp: false,
        verify_email: false,
        show_profile: true,
        verify_work_email: false,
      },
      consent: false,
      extra: {
        parent_request_id: school_request_id,
        school_id,
        parent_id,
        course: {
          title: course_title,
          description,
          duration,
          amount: course_amount,
          image: course_image
        }
      }
    };
  }

  async updateEligibilityLink(school_request_id, payload) {
    const res = await this._api.updateApplicationEligibilityLink(school_request_id, payload);
    this.link = res.row.card_tokenization_link;
  }

  async getGlobalSetting() {
    try {
      const res = await this._api.getGlobalSettings();
      return res.response ?? {};
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  copyEligibilityLink() {
    const link = this.link;
    this._general.copyLink(link);
  }


  ngOnDestroy() {
    this.subscriptions.forEach(element => element.unsubscribe());
  }
}

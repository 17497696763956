<app-header [currentRoute]="'Courses'"></app-header>

<app-default-layout>
  <div class="row align-items-start">
    <div class="col-md-9">
      <div class="pb-5">
        <nav class="card w-100 py-3 px-4 d-none d-md-flex justify-content-between align-items-center flex-row mb-4">
          <h5 class="title m-0">Courses</h5>
        </nav>
        <div *ngIf="!loading">
          <div class="row">
            <div *ngFor="let course of courses; index as i" class="col-12 col-sm-6 col-md-4">
              <div class="card p-0 mb-4">
                <img [src]="course.image" class="card-img-top fit-cover" alt="..." style="height: 250px">
                <div class="card-body">
                  <h5 class="card-title font-size-md fw-normal" style="line-height: 1.4">{{ course.course_title }}</h5>
                  <p class="card-text fw-bold m-0">
                    {{ course.amount | currency:'NGN':'₦':'0.0' }}
                  </p>
                  <button (click)="handleEdit(course)" class="btn btn-outline-secondary btn-sm mt-2">
                    Edit course
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="loading || fetching" class="row">
          <div *ngFor="let item of [1,2,3,4]" class="col-12 col-sm-6 col-md-4">
            <div class="card d-flex flex-col p-0 mb-4">
              <div class="skeleton fit-content" style="position: relative; height: 250px">
                <img src="/assets/images/plus.svg" class="block" alt="img-trasact"/>
              </div>
              <div class="p-4">
                <h5 class="product-title mb-1 d-flex align-items-center skeleton" style="height: 16px">
                  Lorem ipsum dolor sit.
                </h5>
                <div class="d-none d-md-block">
                  <button type="button" class="btn btn-outline-secondary btn-sm skeleton fit-content">
                    View details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4 text-center" *ngIf="courses.length && !pagination.end">
          <button (click)="loadMore()" class="btn btn-link">Load more</button>
        </div>
      </div>
    </div>
    <div class="col-md-3 d-none d-md-flex">
      <div class="card mb-4 text-center w-100">
        <div (click)="modal = 'add'" class="d-flex flex-column align-items-center cursor-pointer py-4">
          <i class="fa fa-plus text-info" style="font-size: 1.4rem;"></i>
          <small class="mt-2">Add course</small>
        </div>
      </div>
    </div>
  </div>

  <button (click)="modal = 'add'" class="floating-action-button d-md-none">
    <i class="fa fa-plus"></i>
  </button>
</app-default-layout>

<app-dialog *ngIf="modal === 'edit'" (close)="modal = ''">
  <app-edit-course [course]="current" (done)="handleEditDone($event)"></app-edit-course>
</app-dialog>

<app-dialog *ngIf="modal === 'add'" (close)="modal = ''">
  <app-add-course (done)="handleAddDone()"></app-add-course>
</app-dialog>
